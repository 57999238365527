import { ModalProvider } from '@air/provider-modal';
import { ErrorBoundary } from '@air/utils-error';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';

import { inter } from '~/fonts/inter';
import { ROOT_ELEMENT_ID } from '~/hooks/useMouseSelection';
import { AccountProvider } from '~/providers/AccountProvider';

interface AppProviderLayoutProps {
  children: ReactNode;
}

/**
 * Eventually, after the core split, this component should be removed
 * and all of the shared providers should be moved to _app.tsx
 */
const AppProviderLayout = memo(({ children }: AppProviderLayoutProps) => {
  return (
    <div className={classNames('h-full', inter.className, inter.variable)} id={ROOT_ELEMENT_ID}>
      <>
        <ErrorBoundary>
          <ModalProvider>{children}</ModalProvider>
        </ErrorBoundary>
      </>
    </div>
  );
});

AppProviderLayout.displayName = 'AppProviderLayout';

export const getLayout = (page: ReactNode) => {
  return (
    <AppProviderLayout>
      <AccountProvider>{page}</AccountProvider>
    </AppProviderLayout>
  );
};
