import { Members } from '@air/api';
import { useCallback } from 'react';

import { sanitizeEmail } from '~/utils/EmailUtils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface AddMemberByInviteTokenParams {
  userEmail: string;
  tokenValue: string;
}

export const useAddMemberByInviteToken = () => {
  const addMemberByInviteToken = useCallback(async ({ userEmail, tokenValue }: AddMemberByInviteTokenParams) => {
    try {
      await Members.addByToken({ userEmail: sanitizeEmail(userEmail), tokenValue });
    } catch (error) {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to add a new member by invite token',
        metadata: {
          data: {
            tokenValue,
            userEmail,
          },
        },
      });
    }
  }, []);

  return {
    addMemberByInviteToken,
  };
};
